<template>
  <div id="login-page">
    <img src="/assets/logo_g.svg" alt="Zatecky Gus" class="top-logo" />
    <form :class="{ invalid: !valid, bordered: true }" @submit.prevent="submit">
      <h2>
        Добро пожаловать <br />
        на портал Жатецкого Гуся
      </h2>
      <p class="t1">Подтвердите свою личность, чтобы войти</p>
      <label>
        <span>Логин</span>
        <input type="text" v-model="formData.email" />
      </label>
      <i>Неверный логин или пароль</i>
      <label class="pw">
        <span>Пароль</span>
        <input :type="passType" v-model="formData.password" />
        <button
          class="pa"
          title="показать пароль"
          @click="passwordShow = !passwordShow"
          type="button"
        ></button>
      </label>
      <i>&nbsp;</i>
      <button :disabled="submitDisabled">Войти</button>
    </form>
  </div>
</template>

<script>
import transport from "../../mixins/transport";
import axios from "axios";

export default {
  name: "Login",
  mixins: [transport],
  data: () => ({
    valid: true,
    formData: {
      email: "",
      password: "",
    },
    rules: {
      login: [(v) => !!v || "Введите логин"],
      password: [(v) => !!v || "Введите пароль"],
    },
    passwordShow: false,
    loading: false,
  }),
  computed: {
    passType() {
      return this.passwordShow ? "text" : "password";
    },
    submitDisabled() {
      return !(this.formData.email.length && this.formData.password.length);
    },
  },
  methods: {
    submit() {
      if (this.loading) return;
      this.loading = true;
      axios.get("/sanctum/csrf-cookie").then(() => {
        axios
          .post("/api/login", this.formData)
          .then((response) => {
            window.username = response.data.name;
            window.localStorage.setItem("username", response.data.name);
            if (response.data.role !== "promoter") {
              window.location.href = "/" + response.data.role;
              return;
            }
            this.$router.push({ name: "Game" });
          })
          .catch((error) => {
            console.log(error);
            if (error.response?.data?.message) {
              this.$root.$refs.app.message(error.response.data.message);
            } else if (error.message) {
              this.$root.$refs.app.message(error.message);
            } else {
              this.$root.$refs.app.message("Не удалось выполнить запрос");
            }
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
  },
};
</script>
<style lang="scss">
body {
  background: #f1e6be url("/assets/bg1.svg") no-repeat fixed top center;
  background-size: cover;
}
#login-page {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  min-height: 700px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  padding: 0 10px;

  .top-logo {
    width: 144px;
  }

  form {
    width: 100%;
    max-width: 416px;
    background: #fff;
    background-size: 100% auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 16px 184px;
    box-shadow: 0 13px 84px rgba(6, 13, 30, 0.15);

    &::after {
      background: #fff url("/assets/fbg.png") center bottom no-repeat;
      background-size: 100% auto;
    }

    h2 {
      text-align: center;
    }
    .t1 {
      margin-top: 28px;
      margin-bottom: 24px;
    }

    label {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 240px;
      height: 40px;
      position: relative;
      border: 1px solid #9b9ea5;
      border-radius: 8px;
      padding: 0 8px;
      background: #ffffff;

      span {
        position: absolute;
        left: 8px;
        top: -12px;
        padding: 0 4px;
        background: white;
        color: #9b9ea5;
      }

      input {
        height: 22px;
        flex: 1;
        padding: 0 8px;
        border: none;
        outline: none;
      }

      button {
        width: 24px;
        height: 24px;
        padding: 0;
        margin: 0;
        border: none;
        background: none center no-repeat;
        background-size: 16px auto;
      }

      &.pw {
        input[type="password"] + button {
          background-image: url(/assets/hide.svg);
        }
        input[type="text"] + button {
          background-image: url(/assets/show.svg);
        }
      }

      &:focus-within {
        border-color: #3a3c3e;

        span {
          color: #3a3c3e;
        }
      }
    }

    i {
      display: block;
      font-size: 12px;
      font-style: normal;
      font-weight: 350;
      line-height: 14px;
      margin-bottom: 16px;
      text-align: left;
      width: 240px;
      padding: 0 16px;
      visibility: hidden;
    }

    &.invalid {
      color: var(--Brand_Red);
      label {
        border-color: var(--Brand_Red);
        span {
          color: var(--Brand_Red);
        }
      }
      i {
        visibility: visible;
      }
    }

    > button {
      border-radius: 8px;
      border: 1px solid var(--black-40);
      background: #ffc425
        radial-gradient(
          78.38% 72.79% at 50% 50%,
          #fae8b8 0%,
          #ffc425 51%,
          #f89600 100%
        );
      padding: 12px 12px 10px;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      text-transform: uppercase;
      width: 240px;
      position: relative;

      &::after {
        display: block;
        content: "";
        background: url("data:image/svg+xml,%3Csvg width='240' height='12' viewBox='0 0 240 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath id='Subtract' fill-rule='evenodd' clip-rule='evenodd' d='M0 -5.79904e-05V4.00006C0 8.41834 3.58173 12.0001 8.00001 12.0001H232C236.418 12.0001 240 8.41834 240 4.00006V-6.10352e-05C240 4.41822 236.418 7.99994 232 7.99994H8.00001C3.58173 7.99994 0 4.41822 0 -5.79904e-05Z' fill='%23383D4B'/%3E%3C/svg%3E")
          center no-repeat;
        background-size: 100% auto;
        width: 240px;
        height: 12px;
        position: absolute;
        left: -1px;
        bottom: -5px;
      }

      &:disabled {
        border-color: var(--black-20);
        background: #ffe9af;
        border-bottom-width: 3px;

        &::after {
          visibility: hidden;
        }
      }
    }
  }
}
</style>
